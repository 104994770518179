import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

const pauseVideo = (previousSlideIndex, slides) => {
    const video = slides[previousSlideIndex].querySelector('video');
    video != null ? video.pause() : false;
}

const playSliderVideo = (button) => {
    button.nextElementSibling.play();
    button.nextElementSibling.controls = true;
    button.style.display = 'none';
}

const setupSliderVideo = () => {
    const playButtons = document.querySelectorAll('[data-js="sliderPlayButton"]');
    [...playButtons].forEach(button => {
        button.addEventListener('click', (e) => playSliderVideo(e.currentTarget));
    });
}

export function imageSlider() {
    const imageSliders = document.querySelectorAll('[data-js="image-slider"]');
    imageSliders.length > 1 ? setupSliderVideo() : false;

	imageSliders.forEach(function(element, index) {
		element.classList.add('image-slider-' + index);

		const swiper = new Swiper('.image-slider-' + index, {
			loop: true,
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
            },
            on: {
                slideChange: function() {
                    pauseVideo(this.previousIndex, this.slides);
                }
            }
        });
	});
}