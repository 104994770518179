export function SubMenuHandler() {

    const menuToggle = document.querySelector('.js-toggle-submenu');
    const subMenu = document.querySelector('.main-nav-submenu-wrap');
    const translateToggle = document.querySelector('.js-open-translator');

    menuToggle.onclick = function() {
        subMenu.classList.toggle('visible');
        if(subMenu.classList.contains('visible')) {
            this.children.Burger.style.display = 'none';
            this.children.Cross.style.display = 'block';
        } else {
            this.children.Burger.style.display = 'block';
            this.children.Cross.style.display = 'none';
        }
    }

    const searchToggle = document.querySelectorAll('.js-toggle-search');
    const searchField = document.querySelector('.search-boi');
    searchToggle.forEach(function(toggle){
        toggle.onclick = function() {
            searchField.classList.toggle('visible');
            document.getElementById('search').focus();
        }
    })

    translateToggle.onclick = function() {
        document.querySelector('.translate-wrap').classList.add('visible');
    }
}

export function MobileMenuHandler() {

    const mobileMenuToggle = document.querySelector('.js-toggle-mobilemenu');
    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenuToggle.onclick = function() {
        mobileMenu.classList.toggle('visible');
        if(mobileMenu.classList.contains('visible')) {
            this.children.Burger.style.display = 'none';
            this.children.Cross.style.display = 'block';
            document.body.style.overflow = 'hidden';
        } else {
            this.children.Burger.style.display = 'block';
            this.children.Cross.style.display = 'none';
            document.body.style.overflow = 'auto';
        }
    }

}