export function formValidation() {
    const submitBtn = document.querySelector('.js-validate-form');

    submitBtn.onclick = function(event) {
        console.log('asd');
        document.getElementById('error').style.display = 'none';
        let error = 0;
        const fields = document.querySelectorAll('.validate');
        fields.forEach(function(field){
            if(field.value === '') {
                error++;
                field.classList.add('error');
            }
        })
        if(error > 0) {
            document.getElementById('error').style.display = 'block';
        } else {
            document.getElementById('bookingForm').submit();
        }
    }
}