const CLOSED_BUTTON_TEXT = 'Läs mer';
const OPEN_BUTTON_TEXT = 'Stäng';

const descriptionIsOpen = (description) => {
    const descriptionWrapper = document.querySelector(`[data-id='${description}']`)
    return descriptionWrapper.classList.contains('open');
}

const toggleButtonText = (button) => {
    descriptionIsOpen(button.dataset.target) ?
        button.innerHTML = CLOSED_BUTTON_TEXT :
        button.innerHTML = OPEN_BUTTON_TEXT
}

const toggleDescription = (button) => {
    const target = button.dataset.target;
    const descriptionWrapper = document.querySelector(`[data-id='${target}']`);

    toggleButtonText(button);
    descriptionWrapper.classList.toggle('open');
}

export function toggleRoomDescription() {
    const descriptionButton = document.querySelectorAll('[data-js="toggleDescription"]');

    [...descriptionButton].forEach(button => {
        button.addEventListener('click', (e) => {
            toggleDescription(e.currentTarget);
        });
    });
}