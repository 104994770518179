import '@babel/polyfill';
import './polyfills.js';

import { SubMenuHandler } from './navigation';
import { MobileMenuHandler } from './navigation';
import { CourseFilter } from './course-filter';
import { ContactFilter } from './contact-filter';
import { swiperHandler } from './swiper';
import { imageSlider } from './image-slider';
import { gallery } from './gallery';
import { formValidation } from './validate-form';
import { toggleRoomDescription } from './room-card';
import { setupMenuToggle } from './sub-menu';
//Navigation functions :-)
SubMenuHandler();

if(window.innerWidth < 1000) {
    MobileMenuHandler();
}

//Run functions if the relevant elements are present
if (document.querySelectorAll('[data-js="toggleSubMenu"]')) {
	setupMenuToggle();
}
if (document.querySelectorAll('[data-js="toggleDescription"]')) {
	toggleRoomDescription();
}
if(document.getElementById('courseSearch')) {
    CourseFilter();
}
if(document.getElementById('contactFilter')) {
    ContactFilter();
}
if(document.getElementsByClassName('swiper-container')) {
    swiperHandler();
    gallery();
}
if (document.querySelectorAll('[data-js="image-slider"]').length > 0) {
	imageSlider();
	
	if (document.querySelectorAll('.js-photo')) {
		gallery();
	}
}
if(document.getElementById('bookingForm')) {
    formValidation();
}

//Cookie accept+storage
let disclaimer = localStorage;
if( localStorage.getItem('cookie-bar') ) {
	document.querySelector('.cookie-bar').classList.add('hidden');
}
document.querySelector('#acceptcookies').onclick = function() {
	if (typeof localStorage === 'object') {
		try {
			localStorage.setItem('cookie-bar', true);
			document.querySelector('.cookie-bar').classList.add('hidden');
		} catch (e) {
			Storage.prototype._setItem = Storage.prototype.setItem;
			Storage.prototype.setItem = function() {};
			document.querySelector('.cookie-bar').classList.add('hidden');
		}
	}  
};
