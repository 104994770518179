import { removeItem } from './remove-item';
export function ContactFilter() {
    let filterButtons = document.querySelectorAll('.js-contact-filter');
    let contactCards = document.querySelectorAll('.js-contact');
    let categories = [];

    filterButtons.forEach(function(item){

        item.onclick = function(){
            item.classList.toggle('active');
            let filter = item.dataset.filter.toString();

            if(!item.classList.contains('active')) {
                removeItem(categories, filter);
            } else {
                categories.push(filter);
            }

            contactCards.forEach(function(contact){
                contact.style.display = 'none';
                let tags = contact.dataset.tags;
                tags = tags.split(',');
                tags.forEach(function(tag){
                    let tagMatches = (categories.indexOf(tag) > -1);
                    if(tagMatches) {
                        contact.style.display = 'block';
                    }
                });
            });

            if(categories.length <= 0) {
                contactCards.forEach(function(contact){
                    contact.style.display = 'block';
                });
            }
        }

    });
}