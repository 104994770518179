export function CourseFilter() {
    const searchField = document.getElementById('courseSearch');

    searchField.onkeyup = function() {
        let value = this.value;
        const courses = document.querySelectorAll('.course-list-row');

        courses.forEach(function(course) {

            course.classList.add('hidden');
            let courseData = {
                "name": course.dataset.name,
                "tempo": course.dataset.tempo,
                "distance": course.dataset.distance,
                "appliable": course.dataset.appliable,
                "visible": false
            }

            Object.keys(courseData).forEach(function(key) {
                let dataValue = courseData[key];

                if(typeof dataValue === 'string') {
                    dataValue = dataValue.toLowerCase();
                    var result = dataValue.match(new RegExp(value, "i"));
                    course.classList.remove('visible');

                    if(result != null && result.length >= 1) {
                        courseData.visible = true;
                    }
                }
            })

            if(courseData.visible == true) {
                course.classList.add('visible');
                course.classList.remove('hidden');
            }
        });
    }

	searchField.onkeyup();
}