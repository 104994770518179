import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

// (function () {
//     if ( typeof NodeList.prototype.forEach === "function" ) return false;
//     NodeList.prototype.forEach = Array.prototype.forEach;
// })();
export function swiperHandler() {
	//Need to iterate over sliders so it doesnt bug out with multiple instances
	let swipercontainer = document.querySelectorAll('.swiper-container');
	swipercontainer.forEach(function(element, index){

		const slideCount = document.getElementById('slideCount');
        const $this = element;
		$this.classList.add('instance-' + index);


		const swiper = new Swiper('.instance-' + index, {
			// Optional parameters
			direction: 'horizontal',
			loop: false,
			effect: 'slide',
			speed: 300,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
			on: {
				init: function () {
					let totalImages = this.imagesToLoad.length;
					slideCount.innerHTML = `${totalImages} bilder totalt`;
				},
			},
            slidesPerView: 2,
            spaceBetween: 24,
			breakpoints: {

				800: {
					effect: 'slide',
					speed: 200,
					touchAngle: 45,
                    slidesPerView: 1
				}
			}
		});
	});
}