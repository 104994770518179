const MENU_OPEN_CLASS = 'open';

const subMenuIsOpen = (menu) => {
    return menu.classList.contains(MENU_OPEN_CLASS);
}

const toggleMenuButtonStyle = (menu) => {
    let button = document.querySelector('.sub-page-menu__button');

    subMenuIsOpen(menu) ? 
        button.innerHTML = '&#10005;' :
        button.innerHTML = '<span></span>'
}

const toggleSubMenu = (button) => {
    const target = button.dataset.target;
    const menuWrapper = document.querySelector(`[data-js='${target}']`);
    menuWrapper.classList.toggle(MENU_OPEN_CLASS);
    toggleMenuButtonStyle(menuWrapper);
}

const toggleChildrenMenu = (button) => {
    button.nextElementSibling.classList.toggle('open');
    button.nextElementSibling.classList.contains('open') ?
        button.innerHTML = '&#10005;' :
        button.innerHTML = '&#43;';
}

const addClickEventToSubButtons = () => {
    const subButtons = document.querySelectorAll('.sub-button');

    [...subButtons].forEach(button => {
        button.addEventListener('click', (e) => {
            toggleChildrenMenu(e.currentTarget);
        });
    });
}

const addChildrenMenuButton = () => {
    const menuWithChildren = document.querySelectorAll('.page_item_has_children > a');

    menuWithChildren.forEach(item => {
        const subButton = '<span class="sub-button">&#43;</span>';
        item.insertAdjacentHTML('afterend', subButton);
    });

    addClickEventToSubButtons();
}

const checkWindowWidth = (e) => {
    return e.matches;
}

export function setupMenuToggle() {
    const subMenuButton = document.querySelectorAll('[data-js="toggleSubMenu"]');

    addChildrenMenuButton();

    [...subMenuButton].forEach(button => {
        button.addEventListener('click', (e) => {
            toggleSubMenu(e.currentTarget);
        });
    });
}