export function gallery() {
    const PhotoSwipe = require('photoswipe');
	const PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.js');

    //Photoswipe
    var pswpElement = document.querySelectorAll('.pswp')[0];

    var $thumbnails = document.querySelectorAll('.js-photo');
    

    function parseThumbnailElements() {
        var items = [];
        $thumbnails.forEach(function(thumbnail) {
            items.push({
                src: thumbnail.href,
                w: thumbnail.dataset.size.split('x')[0],
                h: thumbnail.dataset.size.split('x')[1],
                title: thumbnail.dataset.caption
            });
        });
        return items;
    }

    function openPhotoSwipe(index) {
        // define options (if needed)
        var options = {
            index: index,
            showHideOpacity: true,
            getThumbBoundsFn: function(index) {
                var thumbnail = $thumbnails[index];

                var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;

                var rect = thumbnail.getBoundingClientRect();
                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }
        };
        // Initializes and opens PhotoSwipe
        var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, parseThumbnailElements(), options);
        gallery.init();
    }

    document.querySelectorAll('.js-photo').forEach(function(item) {
        $thumbnails = Array.from($thumbnails);
        item.onclick = function(event) {
            openPhotoSwipe($thumbnails.indexOf(item));
            event.preventDefault();
            return false;
        }
    });
}